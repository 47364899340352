import React, { useState, useEffect } from 'react'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Box,
  Stack
} from '@mui/material'
import { Home, Person, ListAlt, Reorder, Edit } from '@mui/icons-material'

import { getParams } from '../helpers/urlParams'
import { routePaths } from '../constants/routes'
import Header from '../components/common/Header'
import { SubContainer } from '../components/common/Styled'
import { buildImageLink, formatPrice } from '../helpers'

import StatusTable from '../components/StatusTable'
import { Address, Order as IOrder, OrderStatus } from '../helpers/types'
import { useAppContext } from '../contexts/appContext'
import DeliveryTable from '../components/DeliveryTable'
import { graphql, useStaticQuery } from 'gatsby'
import { fetchOrder } from '../helpers/api/order'
import AddressCard from '../components/AddressCard'
import { useProfileContext } from '../contexts/profileContext'

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
    information: strapiInformation {
      address
      postalCode
      city
      name
      email
      phoneNumber
      strapi_id
      socialLinks {
        title
        url
        color
        logo
        strapi_id
      }
    }
  }
`

const Order = () => {
  const { home, information } = useStaticQuery(query)
  const profile = useProfileContext()
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState<IOrder>(null)
  const [comment, setComment] = useState<string>()
  const id = getParams('id')?.id
  const showSnackbar = useAppContext()?.showSnackbar
  const { name, address, postalCode, city, phoneNumber } = information
  const updateOrder = profile?.updateOrder
  const user = profile?.user

  useEffect(() => {
    const getOrder = async () => {
      setLoading(true)
      const result = await fetchOrder(parseInt(id as string, 10))
      setOrder(result)
      setComment(result.comment)
      setLoading(false)
    }
    if (user) getOrder()
  }, [id, user])

  const handleUpdateOrder = async () => {
    try {
      await updateOrder(
        {
          comment
        },
        order.id
      )
      showSnackbar('success', 'Commande mise à jour !')
    } catch (e) {
      showSnackbar('error', 'Erreur lors de la mise a jour de la commande')
    }
  }

  if (loading || !id || !order || !user) return null

  const { productQuantities, deliveryPrice } = order || {}

  const title = 'Détails de la commande'
  const isHomeDelivery = deliveryPrice > 0

  const localAddress = {
    createdAt: '',
    updatedAt: '',
    address1: address,
    address2: '',
    addressName: name,
    city: city,
    company: null,
    country: 'France',
    firstName: name,
    id: 1,
    landlinePhone: '',
    lastName: '',
    mobilePhone: phoneNumber,
    postalCode,
    otherInformation:
      order.status === OrderStatus.READY_TO_COLLECT
        ? 'Veuillez prendre rendez-vous par téléphone'
        : ''
  } as Address

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <Person />,
      label: 'Mon compte',
      route: routePaths.profile
    },
    {
      icon: <ListAlt />,
      label: title,
      route: routePaths.orders
    },
    {
      icon: <Reorder />,
      label: title
    }
  ]

  return (
    <SubContainer>
      <Header {...{ items, title }} />

      <Box my={2}>
        <Stack direction="row" gap={4}>
          <div style={{ flex: 1 }}>
            <Typography
              variant="h6"
              component="h3"
              style={{ marginBottom: 20 }}
            >
              État de la commande :
            </Typography>
            <StatusTable statusHistory={order.statusHistory} />
          </div>
          <div style={{ flex: 1 }}>
            {isHomeDelivery && (
              <>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ marginBottom: 20 }}
                >
                  Livraison :
                </Typography>
                <DeliveryTable
                  deliveryMode={order.details.deliveryMode}
                  trackCode={order.trackCode}
                  deliveryPrice={deliveryPrice}
                />
              </>
            )}
          </div>
        </Stack>
      </Box>

      <Box my={2}>
        <Typography variant="h6" component="h3">
          Adresses :
        </Typography>
        <Stack mt={3} gap={4} direction="row">
          {isHomeDelivery ? (
            <AddressCard
              addressName={`Adresse de livraison (${order.details.shippingAddress.addressName})`}
              address={order.details.shippingAddress}
            />
          ) : (
            <AddressCard
              addressName="Adresse de récupération"
              address={localAddress}
            />
          )}
          <AddressCard
            addressName={`Adresse de facturation (${order.details.billingAddress.addressName})`}
            address={order.details.billingAddress}
          />
        </Stack>
      </Box>

      <Box my={2}>
        <Typography variant="h6" component="h3">
          Produits :
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ width: '100px' }}>
                  Produit
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="center">Quantité</TableCell>
                <TableCell align="right">Prix unitaire</TableCell>
                <TableCell align="right">Prix total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.details.products?.map(product => {
                const productQuantity = productQuantities[product.id]
                return (
                  <TableRow key={product.id}>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      style={{ width: '100px' }}
                    >
                      <Box
                        component="img"
                        sx={{ height: '80px', borderRadius: '5px' }}
                        src={buildImageLink(product.images[0].url)}
                        alt={product.images[0].alternativeText}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography color="secondary">{product.title}</Typography>
                    </TableCell>
                    <TableCell align="center">{productQuantity}</TableCell>
                    <TableCell align="right">
                      {product.promotion ? (
                        <>
                          <Typography
                            variant="body2"
                            sx={{ textDecoration: 'line-through' }}
                          >
                            {formatPrice(product.price)}
                          </Typography>
                          <Typography variant="body2">
                            {formatPrice(product.promotion)}
                          </Typography>
                        </>
                      ) : (
                        formatPrice(product.price)
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {formatPrice(
                          (product.promotion || product.price) * productQuantity
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell colSpan={3} />
                <TableCell>
                  <Typography>{`Livraison (${order.details.deliveryMode.title})`}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    {deliveryPrice === 0
                      ? 'Gratuit !'
                      : formatPrice(deliveryPrice)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} />
                <TableCell>
                  <Typography variant="h6" fontWeight="bold">
                    Total
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6" fontWeight="bold">
                    {formatPrice(order.totalPrice + deliveryPrice)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {order.comment && order.status !== OrderStatus.PAYMENT_ACCEPTED && (
        <Box mx={2}>
          <Typography variant="h6" component="h3">
            Commentaire :
          </Typography>
          <Card style={{ marginTop: 20 }}>
            <CardContent>{order.comment}</CardContent>
          </Card>
        </Box>
      )}
      {order.status === OrderStatus.PAYMENT_ACCEPTED && (
        <Stack gap={4} alignItems="flex-start">
          <Typography>
            Si vous voulez nous laisser un message à propos de votre commande,
            veuillez l&#39;écrire ici :
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            value={comment}
            multiline
            rows={5}
            maxRows={5}
            style={{
              minWidth: 100,
              width: '100%',
              borderColor: 'white',
              height: 'fit-content'
            }}
            label="Rédigez votre message"
            onChange={e => setComment(e.target.value)}
          />
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="primary"
            onClick={handleUpdateOrder}
            disabled={order.comment === comment}
          >
            Mettre à jour
          </Button>
        </Stack>
      )}
    </SubContainer>
  )
}

export default Order
